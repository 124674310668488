import { useCallback, useMemo } from "react";
import { useRouter } from "next/router";
import { stringify } from "qs";

import { Query } from "custom-types/Query";
import parseNextRouterAsPath from "utils/parseNextRouterAsPath";

/**
 * Generic hook for removing specified keys from the query string
 *
 * Usage:
 * - Call `clearQueryStringKeys` with an array of keys to remove them from the query string.
 * - The URL is updated with the remaining query parameters using shallow routing.
 */

export const clearKeysFromQuery = (
  query: Query,
  keysToRemove: string[],
): Query =>
  Object.fromEntries(
    Object.entries(query).filter(([key]) => !keysToRemove.includes(key)),
  );

const useClearQueryStringKeys = () => {
  const { asPath, push } = useRouter();

  const { path, query: parsedQuery } = useMemo(
    () => parseNextRouterAsPath(asPath),
    [asPath],
  );

  const clearQueryStringKeys = useCallback(
    (keysToRemove: string[], isShallowRouted = true) => {
      push(
        {
          pathname: path,
          query: stringify(clearKeysFromQuery(parsedQuery, keysToRemove)),
        },
        undefined,
        { shallow: isShallowRouted },
      );
    },
    [parsedQuery],
  );

  return clearQueryStringKeys;
};

export default useClearQueryStringKeys;
